<template>
  <ResponsiveModal
    :open="open"
    @onClose="closePinModal"
    :id="id"
    :loading="loading"
  >
    <template v-slot:modal-content>
      <div class="center">
        <div>
          <p class="title mb-10 text-center">{{ headings.title }}</p>
          <p class="subtitle mb-10 text-center">{{ headings.body }}</p>
          <div v-if="formError" class="mb-10 form-error text-center">
            {{ formError }}
          </div>
          <div class="center">
            <PinLayout
              :id="`pin-id-pc-${id}`"
              :pinCount="pinCount"
              :value="enteredPin"
              @onChange="onPinChange"
              @onCompleted="onPinEntered"
              :renderError="formError"
            />
          </div>
          <p
            @click="resetPin"
            v-if="renderForgotPin"
            class="forgot-pin mt-10 mb-10 text-center"
          >
            {{ $t("Forgot PIN?") }}
          </p>

          <div v-else class="mb-10"></div>
          <div class="mb-10">
            <button
              :disabled="loading"
              @click="onSubmit"
              class="button-primary"
            >
              {{ $t("CONFIRM") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </ResponsiveModal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  events: ["onClose", "onActionComplete", "onForgotPin"],
  props: ["open", "mode", "pinError", "id", "loading"],
  mounted() {
    this.computeHeadings();
  },
  watch: {
    pinError(error, oldError) {
      this.formError = error;
      setTimeout(() => {
        this.formError = "";
      }, 5000);
    },
    formError(error, oldError) {
      if (error === "") {
        return;
      }
      setTimeout(() => {
        this.formError = "";
      }, 5000);
    },
    mode(mode, oldMode) {
      this.computeHeadings();
    },
  },

  methods: {
    ...mapActions(["parentalControlPinSet"]),
    closePinModal() {
      this.enteredPin = "";
      this.formError = "";
      this.$emit("onClose");
    },
    computeHeadings() {
      let headingsObj = {};
      if (this.mode === "new") {
        headingsObj.title = this.$t("Set Pin");
        headingsObj.body = this.$t("Enter Pin to watch restricted content");
        this.renderForgotPin = false;
      } else if (this.mode === "edit") {
        this.renderForgotPin = false;
        headingsObj.title = this.$t("Reset Pin");
        headingsObj.body = this.$t("Enter Pin to Reset");
      } else if (this.mode === "confirm") {
        this.renderForgotPin = true;
         headingsObj.title = this.$t("Confirm Pin");
         headingsObj.body = this.$t("Enter Pin to confirm");
       
      } else if(this.mode === "restrict"){
        headingsObj.title = this.$t("Viewing restrictions enabled");
        headingsObj.body = this.$t("To watch the content please enter pin");
      } else {
        this.renderForgotPin = true;
        headingsObj.title = this.$t("Viewing restrictions enabled");
        headingsObj.body = this.$t("To watch the content please enter pin");
      }
      this.headings = headingsObj;
    },
    onPinEntered(pin) {
      this.enteredPin = pin;
    },
    onPinChange(pin) {
      this.enteredPin = pin;
    },

    resetPin() {
      this.closePinModal();
      this.$emit("onForgotPin", true);
    },
    onSubmit() {
      if (this.enteredPin === "") {
        this.formError = this.$t("Please enter a valid pin");
        return;
      }

      if (this.enteredPin.length < this.pinCount) {
        this.formError = this.$t("Pin should be 4 digits");
        return;
      }

      this.$emit("onActionComplete", this.enteredPin);
      this.enteredPin = "";
      this.formError = "";
    },
  },
  components: {
    ResponsiveModal: () => import("@/components/utils/ResponsiveModal.vue"),
    PinLayout: () => import("@/components/utils/PinLayout.vue"),
  },
  data() {
    return {
      enteredPin: "",
      pinCount: 4,
      headings: {
        title: "",
        body: "",
      },
      formError: "",
      renderForgotPin: true,
    };
  },
};
</script>

<style lang="scss">
@import "./PinModal.scss"
</style>
